import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import pic03 from '../assets/images/pic03.jpg';
import pic08 from '../assets/images/pic08.jpg';
import pic09 from '../assets/images/pic09.jpg';
import pic10 from '../assets/images/pic10.jpg';

var Elements = function Elements(props) {
  return React.createElement(Layout, null, React.createElement(Helmet, null, React.createElement("title", null, "Elements - Forty by HTML5 UP"), React.createElement("meta", {
    name: "description",
    content: "Elements Page"
  })), React.createElement("div", {
    id: "main",
    className: "alt"
  }, React.createElement("section", {
    id: "one"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("header", {
    className: "major"
  }, React.createElement("h1", null, "Elements")), React.createElement("h2", {
    id: "content"
  }, "Sample Content"), React.createElement("p", null, "Praesent ac adipiscing ullamcorper semper ut amet ac risus. Lorem sapien ut odio odio nunc. Ac adipiscing nibh porttitor erat risus justo adipiscing adipiscing amet placerat accumsan. Vis. Faucibus odio magna tempus adipiscing a non. In mi primis arcu ut non accumsan vivamus ac blandit adipiscing adipiscing arcu metus praesent turpis eu ac lacinia nunc ac commodo gravida adipiscing eget accumsan ac nunc adipiscing adipiscing."), React.createElement("div", {
    className: "grid-wrapper"
  }, React.createElement("div", {
    className: "col-6"
  }, React.createElement("h3", null, "Sem turpis amet semper"), React.createElement("p", null, "Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat commodo eu sed ante lacinia. Sapien a lorem in integer ornare praesent commodo adipiscing arcu in massa commodo lorem accumsan at odio massa ac ac. Semper adipiscing varius montes viverra nibh in adipiscing blandit tempus accumsan.")), React.createElement("div", {
    className: "col-6"
  }, React.createElement("h3", null, "Magna odio tempus commodo"), React.createElement("p", null, "In arcu accumsan arcu adipiscing accumsan orci ac. Felis id enim aliquet. Accumsan ac integer lobortis commodo ornare aliquet accumsan erat tempus amet porttitor. Ante commodo blandit adipiscing integer semper orci eget. Faucibus commodo adipiscing mi eu nullam accumsan morbi arcu ornare odio mi adipiscing nascetur lacus ac interdum morbi accumsan vis mi accumsan ac praesent.")), React.createElement("div", {
    className: "col-4"
  }, React.createElement("h3", null, "Interdum sapien gravida"), React.createElement("p", null, "Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat eu sed ante lacinia sapien lorem accumsan varius montes viverra nibh in adipiscing blandit tempus accumsan.")), React.createElement("div", {
    className: "col-4"
  }, React.createElement("h3", null, "Faucibus consequat lorem"), React.createElement("p", null, "Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat eu sed ante lacinia sapien lorem accumsan varius montes viverra nibh in adipiscing blandit tempus accumsan.")), React.createElement("div", {
    className: "col-4"
  }, React.createElement("h3", null, "Accumsan montes viverra"), React.createElement("p", null, "Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat eu sed ante lacinia sapien lorem accumsan varius montes viverra nibh in adipiscing blandit tempus accumsan."))), React.createElement("hr", {
    className: "major"
  }), React.createElement("h2", {
    id: "elements"
  }, "Elements"), React.createElement("div", {
    className: "grid-wrapper"
  }, React.createElement("div", {
    className: "col-6"
  }, React.createElement("h3", null, "Text"), React.createElement("p", null, "This is ", React.createElement("b", null, "bold"), " and this is ", React.createElement("strong", null, "strong"), ". This is ", React.createElement("i", null, "italic"), " and this is ", React.createElement("em", null, "emphasized"), ". This is ", React.createElement("sup", null, "superscript"), " text and this is ", React.createElement("sub", null, "subscript"), " text. This is ", React.createElement("u", null, "underlined"), " and this is code: ", React.createElement("code", null, "for (;;)"), ". Finally, this is a ", React.createElement("a", {
    href: "#"
  }, "link"), "."), React.createElement("hr", null), React.createElement("h2", null, "Heading Level 2"), React.createElement("h3", null, "Heading Level 3"), React.createElement("h4", null, "Heading Level 4"), React.createElement("hr", null), React.createElement("p", null, "Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat eu sed ante lacinia sapien lorem accumsan varius montes viverra nibh in adipiscing blandit tempus accumsan."), React.createElement("h3", null, "Lists"), React.createElement("div", {
    className: "grid-wrapper"
  }, React.createElement("div", {
    className: "col-6"
  }, React.createElement("h4", null, "Unordered"), React.createElement("ul", null, React.createElement("li", null, "Dolor etiam magna etiam."), React.createElement("li", null, "Sagittis lorem eleifend."), React.createElement("li", null, "Felis dolore viverra.")), React.createElement("h4", null, "Alternate"), React.createElement("ul", {
    className: "alt"
  }, React.createElement("li", null, "Dolor etiam magna etiam."), React.createElement("li", null, "Sagittis lorem eleifend."), React.createElement("li", null, "Felis feugiat viverra."))), React.createElement("div", {
    className: "col-6"
  }, React.createElement("h4", null, "Ordered"), React.createElement("ol", null, React.createElement("li", null, "Dolor etiam magna etiam."), React.createElement("li", null, "Etiam vel lorem sed viverra."), React.createElement("li", null, "Felis dolore viverra."), React.createElement("li", null, "Dolor etiam magna etiam."), React.createElement("li", null, "Etiam vel lorem sed viverra."), React.createElement("li", null, "Felis dolore viverra.")), React.createElement("h4", null, "Icons"), React.createElement("ul", {
    className: "icons"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "icon fa-twitter"
  }, React.createElement("span", {
    className: "label"
  }, "Twitter"))), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "icon fa-facebook"
  }, React.createElement("span", {
    className: "label"
  }, "Facebook"))), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "icon fa-instagram"
  }, React.createElement("span", {
    className: "label"
  }, "Instagram"))), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "icon fa-github"
  }, React.createElement("span", {
    className: "label"
  }, "Github"))), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "icon fa-dribbble"
  }, React.createElement("span", {
    className: "label"
  }, "Dribbble"))), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "icon fa-tumblr"
  }, React.createElement("span", {
    className: "label"
  }, "Tumblr")))), React.createElement("ul", {
    className: "icons"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "icon alt fa-twitter"
  }, React.createElement("span", {
    className: "label"
  }, "Twitter"))), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "icon alt fa-facebook"
  }, React.createElement("span", {
    className: "label"
  }, "Facebook"))), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "icon alt fa-instagram"
  }, React.createElement("span", {
    className: "label"
  }, "Instagram")))))), React.createElement("h4", null, "Definition"), React.createElement("dl", null, React.createElement("dt", null, "Item1"), React.createElement("dd", null, React.createElement("p", null, "Lorem ipsum dolor vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent. Lorem ipsum dolor.")), React.createElement("dt", null, "Item2"), React.createElement("dd", null, React.createElement("p", null, "Lorem ipsum dolor vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent. Lorem ipsum dolor.")), React.createElement("dt", null, "Item3"), React.createElement("dd", null, React.createElement("p", null, "Lorem ipsum dolor vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent. Lorem ipsum dolor."))), React.createElement("h4", null, "Actions"), React.createElement("ul", {
    className: "actions"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special"
  }, "Default")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button"
  }, "Default"))), React.createElement("ul", {
    className: "actions small"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special small"
  }, "Small")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button small"
  }, "Small"))), React.createElement("div", {
    className: "grid-wrapper"
  }, React.createElement("div", {
    className: "col-6"
  }, React.createElement("ul", {
    className: "actions vertical"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special"
  }, "Default")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button"
  }, "Default")))), React.createElement("div", {
    className: "col-6"
  }, React.createElement("ul", {
    className: "actions vertical small"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special small"
  }, "Small")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button small"
  }, "Small")))), React.createElement("div", {
    className: "col-6"
  }, React.createElement("ul", {
    className: "actions vertical"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special fit"
  }, "Default")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button fit"
  }, "Default")))), React.createElement("div", {
    className: "col-6"
  }, React.createElement("ul", {
    className: "actions vertical small"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special small fit"
  }, "Small")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button small fit"
  }, "Small"))))), React.createElement("h4", null, "Pagination"), React.createElement("ul", {
    className: "pagination"
  }, React.createElement("li", null, React.createElement("span", {
    className: "button small disabled"
  }, "Prev")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "page active"
  }, "1")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "page"
  }, "2")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "page"
  }, "3")), React.createElement("li", null, React.createElement("span", null, "\u2026")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "page"
  }, "8")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "page"
  }, "9")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "page"
  }, "10")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button small"
  }, "Next"))), React.createElement("h3", null, "Blockquote"), React.createElement("blockquote", null, "Fringilla nisl. Donec accumsan interdum nisi, quis tincidunt felis sagittis eget tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan faucibus. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis."), React.createElement("h3", null, "Table"), React.createElement("h4", null, "Default"), React.createElement("div", {
    className: "table-wrapper"
  }, React.createElement("table", null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Name"), React.createElement("th", null, "Description"), React.createElement("th", null, "Price"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "Item1"), React.createElement("td", null, "Ante turpis integer aliquet porttitor."), React.createElement("td", null, "29.99")), React.createElement("tr", null, React.createElement("td", null, "Item2"), React.createElement("td", null, "Vis ac commodo adipiscing arcu aliquet."), React.createElement("td", null, "19.99")), React.createElement("tr", null, React.createElement("td", null, "Item3"), React.createElement("td", null, " Morbi faucibus arcu accumsan lorem."), React.createElement("td", null, "29.99")), React.createElement("tr", null, React.createElement("td", null, "Item4"), React.createElement("td", null, "Vitae integer tempus condimentum."), React.createElement("td", null, "19.99")), React.createElement("tr", null, React.createElement("td", null, "Item5"), React.createElement("td", null, "Ante turpis integer aliquet porttitor."), React.createElement("td", null, "29.99"))), React.createElement("tfoot", null, React.createElement("tr", null, React.createElement("td", {
    colSpan: "2"
  }), React.createElement("td", null, "100.00"))))), React.createElement("h4", null, "Alternate"), React.createElement("div", {
    className: "table-wrapper"
  }, React.createElement("table", {
    className: "alt"
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Name"), React.createElement("th", null, "Description"), React.createElement("th", null, "Price"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "Item1"), React.createElement("td", null, "Ante turpis integer aliquet porttitor."), React.createElement("td", null, "29.99")), React.createElement("tr", null, React.createElement("td", null, "Item2"), React.createElement("td", null, "Vis ac commodo adipiscing arcu aliquet."), React.createElement("td", null, "19.99")), React.createElement("tr", null, React.createElement("td", null, "Item3"), React.createElement("td", null, " Morbi faucibus arcu accumsan lorem."), React.createElement("td", null, "29.99")), React.createElement("tr", null, React.createElement("td", null, "Item4"), React.createElement("td", null, "Vitae integer tempus condimentum."), React.createElement("td", null, "19.99")), React.createElement("tr", null, React.createElement("td", null, "Item5"), React.createElement("td", null, "Ante turpis integer aliquet porttitor."), React.createElement("td", null, "29.99"))), React.createElement("tfoot", null, React.createElement("tr", null, React.createElement("td", {
    colSpan: "2"
  }), React.createElement("td", null, "100.00")))))), React.createElement("div", {
    className: "col-6"
  }, React.createElement("h3", null, "Buttons"), React.createElement("ul", {
    className: "actions"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special"
  }, "Special")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button"
  }, "Default"))), React.createElement("ul", {
    className: "actions"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button big"
  }, "Big")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button"
  }, "Default")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button small"
  }, "Small"))), React.createElement("ul", {
    className: "actions"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special big"
  }, "Big")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special"
  }, "Default")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special small"
  }, "Small"))), React.createElement("ul", {
    className: "actions fit"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special fit"
  }, "Fit")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button fit"
  }, "Fit"))), React.createElement("ul", {
    className: "actions fit small"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special fit small"
  }, "Fit + Small")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button fit small"
  }, "Fit + Small"))), React.createElement("ul", {
    className: "actions"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special icon fa-search"
  }, "Icon")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button icon fa-download"
  }, "Icon"))), React.createElement("ul", {
    className: "actions"
  }, React.createElement("li", null, React.createElement("span", {
    className: "button special disabled"
  }, "Special")), React.createElement("li", null, React.createElement("span", {
    className: "button disabled"
  }, "Default"))), React.createElement("h3", null, "Form"), React.createElement("form", {
    method: "post",
    action: "#"
  }, React.createElement("div", {
    className: "grid-wrapper"
  }, React.createElement("div", {
    className: "col-6"
  }, React.createElement("div", {
    className: "mb-5"
  }, React.createElement("input", {
    type: "text",
    name: "demo-name",
    id: "demo-name",
    defaultValue: "",
    placeholder: "Name"
  }))), React.createElement("div", {
    className: "col-6"
  }, React.createElement("div", {
    className: "mb-5"
  }, React.createElement("input", {
    type: "email",
    name: "demo-email",
    id: "demo-email",
    defaultValue: "",
    placeholder: "Email"
  }))), React.createElement("div", {
    className: "col-12"
  }, React.createElement("div", {
    className: "select-wrapper mb-5"
  }, React.createElement("select", {
    name: "demo-category",
    id: "demo-category"
  }, React.createElement("option", {
    defaultValue: ""
  }, "- Category -"), React.createElement("option", {
    value: "1"
  }, "Manufacturing"), React.createElement("option", {
    value: "1"
  }, "Shipping"), React.createElement("option", {
    value: "1"
  }, "Administration"), React.createElement("option", {
    value: "1"
  }, "Human Resources")))), React.createElement("div", {
    className: "col-4"
  }, React.createElement("div", {
    className: "mb-5"
  }, React.createElement("input", {
    type: "radio",
    id: "demo-priority-low",
    name: "demo-priority",
    defaultChecked: true
  }), React.createElement("label", {
    htmlFor: "demo-priority-low"
  }, "Low"))), React.createElement("div", {
    className: "col-4"
  }, React.createElement("div", {
    className: "mb-5"
  }, React.createElement("input", {
    type: "radio",
    id: "demo-priority-normal",
    name: "demo-priority"
  }), React.createElement("label", {
    htmlFor: "demo-priority-normal"
  }, "Normal"))), React.createElement("div", {
    className: "col-4"
  }, React.createElement("div", {
    className: "mb-5"
  }, React.createElement("input", {
    type: "radio",
    id: "demo-priority-high",
    name: "demo-priority"
  }), React.createElement("label", {
    htmlFor: "demo-priority-high"
  }, "High"))), React.createElement("div", {
    className: "col-6"
  }, React.createElement("div", {
    className: "mb-5"
  }, React.createElement("input", {
    type: "checkbox",
    id: "demo-copy",
    name: "demo-copy"
  }), React.createElement("label", {
    htmlFor: "demo-copy"
  }, "Email me a copy"))), React.createElement("div", {
    className: "col-6"
  }, React.createElement("div", {
    className: "mb-5"
  }, React.createElement("input", {
    type: "checkbox",
    id: "demo-human",
    name: "demo-human",
    defaultChecked: true
  }), React.createElement("label", {
    htmlFor: "demo-human"
  }, "I am a human"))), React.createElement("div", {
    className: "col-12"
  }, React.createElement("div", {
    className: "mb-5"
  }, React.createElement("textarea", {
    name: "demo-message",
    id: "demo-message",
    placeholder: "Enter your message",
    rows: "6"
  }))), React.createElement("div", {
    className: "col-12"
  }, React.createElement("ul", {
    className: "actions"
  }, React.createElement("li", null, React.createElement("input", {
    type: "submit",
    value: "Send Message",
    className: "special"
  })), React.createElement("li", null, React.createElement("input", {
    type: "reset",
    value: "Reset"
  })))))), React.createElement("h3", null, "Image"), React.createElement("h4", null, "Fit"), React.createElement("span", {
    className: "image fit"
  }, React.createElement("img", {
    src: pic03,
    alt: ""
  })), React.createElement("div", {
    className: "box alt"
  }, React.createElement("div", {
    className: "grid-wrapper"
  }, React.createElement("div", {
    className: "col-4"
  }, React.createElement("span", {
    className: "image fit"
  }, React.createElement("img", {
    src: pic08,
    alt: ""
  }))), React.createElement("div", {
    className: "col-4"
  }, React.createElement("span", {
    className: "image fit"
  }, React.createElement("img", {
    src: pic09,
    alt: ""
  }))), React.createElement("div", {
    className: "col-4"
  }, React.createElement("span", {
    className: "image fit"
  }, React.createElement("img", {
    src: pic10,
    alt: ""
  }))), React.createElement("div", {
    className: "col-4"
  }, React.createElement("span", {
    className: "image fit"
  }, React.createElement("img", {
    src: pic10,
    alt: ""
  }))), React.createElement("div", {
    className: "col-4"
  }, React.createElement("span", {
    className: "image fit"
  }, React.createElement("img", {
    src: pic08,
    alt: ""
  }))), React.createElement("div", {
    className: "col-4"
  }, React.createElement("span", {
    className: "image fit"
  }, React.createElement("img", {
    src: pic09,
    alt: ""
  }))), React.createElement("div", {
    className: "col-4"
  }, React.createElement("span", {
    className: "image fit"
  }, React.createElement("img", {
    src: pic09,
    alt: ""
  }))), React.createElement("div", {
    className: "col-4"
  }, React.createElement("span", {
    className: "image fit"
  }, React.createElement("img", {
    src: pic10,
    alt: ""
  }))), React.createElement("div", {
    className: "col-4"
  }, React.createElement("span", {
    className: "image fit"
  }, React.createElement("img", {
    src: pic08,
    alt: ""
  }))))), React.createElement("h4", null, "Left & Right"), React.createElement("p", null, React.createElement("span", {
    className: "image left"
  }, React.createElement("img", {
    src: pic09,
    alt: ""
  })), "Lorem ipsum dolor sit accumsan interdum nisi, quis tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget tempus vestibulum ante ipsum primis in faucibus magna blandit adipiscing eu felis iaculis."), React.createElement("p", null, React.createElement("span", {
    className: "image right"
  }, React.createElement("img", {
    src: pic10,
    alt: ""
  })), "Lorem ipsum dolor sit accumsan interdum nisi, quis tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget tempus vestibulum ante ipsum primis in faucibus magna blandit adipiscing eu felis iaculis."), React.createElement("h3", null, "Box"), React.createElement("div", {
    className: "box"
  }, React.createElement("p", null, "Felis sagittis eget tempus primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget. tempus euismod. Magna sed etiam ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus lorem ipsum.")), React.createElement("h3", null, "Preformatted"), React.createElement("pre", null, React.createElement("code", null, "i = 0; print 'It took ' + i + ' iterations to sort the deck.';"))))))));
};

export default Elements;